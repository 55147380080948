
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: 'FIREBASE_APP_API_KEY',
  authDomain: 'FIREBASE_APP_AUTH_DOMAIN',
  projectId: 'FIREBASE_APP_PROJECT_ID',
  storageBucket: 'FIREBASE_APP_STORAGE_BUCKET',
  messagingSenderId: 'FIREBASE_APP_MESSAGING_SENDER_ID',
  appId: 'FIREBASE_APP_APP_ID',
  measurementId: 'FIREBASE_APP_MEASUREMENT_ID',
};

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app);
const importsStorage = getStorage(app, 'FIREBASE_APP_STORAGE_IMPORTS_BUCKET');

export {
  auth,
  db,
  storage,
  importsStorage
}
