import { createStore } from "@stencil/store";

export interface ISidePanelOpts {
  title: string;
  componentHtml: string;
  size?: 'sm' | 'md' | 'lg' | 'full';
  data: any;
  onClose: Function;
}

const { state } = createStore({
  visible: false,
  title: '',
  componentHtml: '',
  size: 'md',
  data: {} as any,
  onClose: {} as Function,
});

export const openSidePanel = (opts: ISidePanelOpts) => {
  const body = document.querySelector('body');

  state.visible = true;
  body.style.overflow = 'hidden';

  state.title = opts.title;
  state.componentHtml = opts.componentHtml;
  state.data = opts.data;
  state.size = opts.size ? opts.size : 'md';
  state.onClose = opts.onClose;
}

export const closeSidePanel = () => {
  const body = document.querySelector('body');

  state.visible = false;
  body.style.overflow = 'auto';

  if (state.onClose) {
    state.onClose();
  }
}

export default state;
